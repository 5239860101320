export const hamburgerMenu = () => {
  const mobileNav = document.querySelector("nav.mobile");
  const mobileNavToggle = document.querySelector(".hamburger-box.mobile");
  const mobileNavDropDowns = document.querySelectorAll("nav.mobile a.drop");

  if (mobileNav && mobileNavToggle) {
    mobileNavToggle.addEventListener("click", () => {
      mobileNav.classList.toggle("open");
    });

    if (mobileNavDropDowns) {
      mobileNavDropDowns.forEach((dropDown) => {
        dropDown.addEventListener("click", () => {
          dropDown.classList.toggle("open");
        });
      });
    }
  }
};
